import React, { useState } from "react";
import { toast } from "react-toastify";
import { URLAPI } from "../../../Helpers/apimaderas";

export default function ContactForm({ desarrollos, tokenAsesor, pathname }) {
  const { fraccionamientos } = desarrollos;
  const [activeInputBoxes, setActiveInputBoxes] = useState([]);
  const [dataForm, setDataForm] = useState({
    name: "",
    email: "",
    phone: "",
    fraccionamientoId: "",
    message: ""
  });
  const [selectedItem, setSelectedItem] = useState(fraccionamientos[0]?.id || "");
  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchContacSave = async (e) => {
    try {
      e.preventDefault()
      const respAPI = await fetch(`${URLAPI(pathname)}/contact/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "token": tokenAsesor, //token del vendedor
          "nombre": dataForm.name,
          "telefono": dataForm.phone,
          "email": dataForm.email,
          "fraccionamiento": selectedItem,
          "mensaje": dataForm.message
        })
      }).then((resp) => resp.json());
      if (respAPI.message === "Success") {
        toast.success("Enviado Correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: true,
        });
        setDataForm({
          name: "",
          email: "",
          phone: "",
          fraccionamientoId: "",
          message: ""
        });
        setSelectedItem(fraccionamientos[0]?.nombre)
      } else {
        throw new Error(respAPI.message)
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <form className="contact-form"
      onSubmit={async (e) => await fetchContacSave(e)}
    >
      <div className="form-input-item mb-60">
        <label style={activeInputBoxes.includes('name') ? { color: '#FE7878' } : {}} className="input-lebel name">Nombre *</label>
        <input
          name="name"
          className={`input-box name height `}
          type="text"
          required
          onChange={(e) => handleChangeForm(e)}
          value={dataForm.name}
          onClick={() => setActiveInputBoxes(pre => !pre.includes('name') ? [...pre, 'name'] : pre)}
          style={activeInputBoxes.includes('name') ? { borderColor: '#FE7878' } : {}}
        />
      </div>
      <div className="form-input-item mb-60">
        <label style={activeInputBoxes.includes('gmail') ? { color: '#1B74E4' } : {}} className="input-lebel gmail">Correo *</label>
        <input
          name="email"
          className={`input-box gmail height `}
          type="Email"
          required
          onChange={(e) => handleChangeForm(e)}
          value={dataForm.email}
          onClick={() => setActiveInputBoxes(pre => !pre.includes('gmail') ? [...pre, 'gmail'] : pre)}
          style={activeInputBoxes.includes('gmail') ? { borderColor: '#1B74E4' } : {}}
        />
      </div>
      <div className="form-input-item mb-60">
        <label style={activeInputBoxes.includes('gmail') ? { color: '#1B74E4' } : {}} className="input-lebel gmail">Teléfono *</label>
        <input
          name="phone"
          className={`input-box gmail height `}
          type="text"
          required
          onChange={(e) => handleChangeForm(e)}
          value={dataForm.phone}
          onClick={() => setActiveInputBoxes(pre => !pre.includes('gmail') ? [...pre, 'gmail'] : pre)}
          style={activeInputBoxes.includes('gmail') ? { borderColor: '#1B74E4' } : {}}
        />
      </div>
      <div className="form-input-item mb-60">
        <label style={activeInputBoxes.includes('gmail') ? { color: '#1B74E4' } : {}} className="input-lebel gmail">Interes en *</label>
        <select name="fraccionamientoId" className="input-box height cursor-pointer" value={selectedItem} onChange={handleChange}>
          {
            fraccionamientos.map(item => (
              <option key={item.id} value={item.id}>{item.nombre}</option>
            ))
          }
        </select>
      </div>
      <div className="form-input-item mb-40">
        <label style={activeInputBoxes.includes('message') ? { color: '#CE65F3' } : {}} className="input-lebel message">Mensaje *</label>
        <textarea
          maxLength={150}
          name="message"
          className={`input-box message height `}
          onChange={(e) => handleChangeForm(e)}
          value={dataForm.message}
          onClick={() => setActiveInputBoxes(pre => !pre.includes('message') ? [...pre, 'message'] : pre)}
          style={activeInputBoxes.includes('message') ? { borderColor: '#CE65F3' } : {}}
          cols="30"
          rows="10"
          required
        ></textarea>
      </div>
      <div className="form-btn-wrap">
        <button type="submit" value="Send" className="form-btn">
          Enviar
        </button>
      </div>
    </form>
  )
}
