import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import QRCode from "react-qr-code";
import notFoundImg from "../../../../assets/img/notFound.jpg"
import { URLAPI } from "../../../../Helpers/apimaderas";

export default function QrMaderas() {
    const { token: tokenAsesor } = useParams();
    const { pathname } = useLocation();
    const [profileInfo, setProfileInfo] = useState();
    const [config, setConfig] = useState({});

    const fetchGetConfig = async () => {
        try {

            const resp = await fetch(`${URLAPI(pathname)}/config/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                })
            }).then((resp) => resp.json());
            if (resp.success) {
                setConfig(resp.data);
                document.documentElement.style.setProperty('--background-image', `url(${resp.data.background})`);
            } else {
                throw new Error(resp.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const fetchAPI = async () => {
            try {

                await fetchGetConfig();

                const resp = await fetch(`${URLAPI(pathname)}/seller/profile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "token": tokenAsesor
                    })
                }).then((resp) => resp.json());
                if (resp.success) {
                    setProfileInfo(resp);
                } else {
                    throw new Error(resp.message)
                }
            } catch (error) {
                console.log("error: ", error)
            }
        }

        fetchAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const { perfil = {} } = profileInfo?.data || {};
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '40px 0 0 0', height: '100vh', backgroundImage: `url(${config.background})` }}>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '60px 0 0 0' }}/* className="col-xxl-4 col-xl-4 col-lg-4" */>

                {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0 }}>
                    <img
                        width={200}
                        height={60}
                        src={config?.logo}
                        alt="logo"
                    />
                </div> */}
                <div className="bostami-parsonal-info-area" style={{ maxWidth: '320px' }}>
                    <div className="bostami-parsonal-info-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '10px' }}>
                        <div className="bostami-parsonal-info-img" style={{ margin: '-180px 0 0 0', width: '220px', height: '220px' }}>
                            <img
                                width={220}
                                height={220}
                                src={perfil.urlFoto || notFoundImg}
                                alt="author"
                            />
                        </div>

                        {/*   <p className="bostami-parsonal-info-name" style={{ margin: '10px 0', textAlign: 'center', width: '240px' }}>
                            <span><strong>{vendedor.nombre}</strong></span>
                        </p> */}
                        {/* Can be anything instead of `maxWidth` that limits the width. */}
                        <div style={{ height: "auto", margin: "0", maxWidth: 200, width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <QRCode
                                size={200}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={window.location.href.replace('qr/', '')}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className="bostami-parsonal-info-contact" style={{ width: '320px', padding: '0 30px' }}>
                            <div className="bostami-parsonal-info-contact-item">
                                <div
                                    style={{ color: `#E93B81`, fontSize: `22px` }}
                                    className="icon"
                                >
                                    <i className="fa-regular fa-mobile"></i>
                                </div>
                                <div className="text">
                                    <span>Teléfono</span>
                                    <p>{perfil.telefono}</p>
                                </div>
                            </div>
                            <div className="bostami-parsonal-info-contact-item" style={{ wordBreak: 'break-word' }}>
                                <div
                                    style={{ color: `#6AB5B9`, fontSize: `22px` }}
                                    className="icon"
                                >
                                    <i className="fa-regular fa-envelope-open-text"></i>
                                </div>
                                <div className="text">
                                    <span>Correo</span>
                                    <a style={{ color: "#000" }} href={`mailto:${perfil.email}`}>{perfil.email}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        width={200}
                        height={60}
                        src={config?.logo}
                        alt="logo"
                    />
                </div>
            </div>

        </div>
    );
}
