import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import QrMaderas from './components/homes/qr/cdmaderas/QrMaderas';
import Error404Screen from './components/homes/Error404/Error404';

const router = createBrowserRouter([
  {
    path: "/cdmaderas/:token",
    element: <App />
  },
  {
    path: "/segura/:token",
    element: <App />
  },
  {
    path: "/cdmaderas/qr/:token",
    element: <QrMaderas />
  },
  {
    path: "*",
    element: <Error404Screen />
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
