import React from 'react';
// import './App.css';
import "./main.scss"
import "./custom.scss";
import Home3 from "../src/app/resumes/resume-3/page.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ToastContainer />
      <Home3 />
    </>
  );
}

export default App;
