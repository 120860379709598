import React, { useEffect, useState } from "react";
import AboutThree from "../../../components/homes/about/AboutThree";
import HeaderFour from "../../../components/homes/headers/HeaderFour";
import HeaderThree from "../../../components/homes/headers/HeaderThree";
import PersonalInfoThree from "../../../components/homes/personalInfo/PersonalInfoThree";
import PortfolioThree from "../.././../components/homes/portfolio/PortfolioThree";
import ContactThree from "../../../components/homes/contact/ContactThree";
import { URLAPI } from "../../../Helpers/apimaderas";
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
export const metadata = {
  title: "Resume-3 || Bostami - Professional portfolio NextJS Template",
  description:
    "Discover Botami,the most impressive portfolio template for work showcase, blog",
};
export default function Page() {
  const { token: tokenAsesor } = useParams();
  const { pathname } = useLocation();

  const [tab, setTab] = useState("sobremi");
  const [profile, setProfile] = useState({});
  const [config, setConfig] = useState({});

  const menuItems = [
    { id: 1, icon: "fa-light fa-user", text: "Sobre mi", tab: "sobremi" },
    { id: 3, icon: "fa-light fa-briefcase", text: "Desarrollos", tab: "desarrollos" },
    { id: 5, icon: "size-22 fa-light fa-address-book", text: "Contacto", tab: "contacto" },
  ]

  const renderTab = (tab) => {
    const component = {
      "sobremi": <AboutThree profile={profile?.data} />,
      "desarrollos": <PortfolioThree desarrollos={profile?.data} />,
      "contacto": <ContactThree desarrollos={profile?.data?.perfil} tokenAsesor={tokenAsesor} pathname={pathname} />
    }

    return component[tab];
  }

  const fetchGetConfig = async () => {
    try {

      const resp = await fetch(`${URLAPI(pathname)}/config/get`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

        })
      }).then((resp) => resp.json());
      if (resp.success) {
        setConfig(resp.data)
      } else {
        throw new Error(resp.message)
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  useEffect(() => {
    const fetchAPI = async () => {
      try {

        const resp = await fetch(`${URLAPI(pathname)}/seller/profile`, {
          method: 'POST',
          headers: {
            // Authorization: 'Basic WkpRUEtaSE5HVTpab0piWUlYdkRjd3FjZW5QWWhTQ2RUS3dqcGdYcmRwdQ==',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "token": tokenAsesor
          })
        }).then((resp) => resp.json());
        if (resp.success) {
          setProfile(resp);
        } else {
          throw new Error(resp.message)
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    fetchGetConfig();

    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        profile?.success && <div className="page-wrapper home-3" style={{ pointerEvents: profile?.success ? 'auto' : 'none' }}>
          <HeaderFour menuItems={menuItems} tab={tab} setTab={setTab} logo={config?.logo} bgImage={config?.background} />
          <div className="container z-index-3">
            <div className="row">
              <PersonalInfoThree profileInfo={profile} />
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <HeaderThree menuItemsThree={menuItems} tab={tab} setTab={setTab} />
                {renderTab(tab)}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
